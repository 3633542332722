export const OPTIONS = [
  'prefix',
  'templateDelimiters',
  'rootInterface',
  'preloadData',
  'handler'
]

export const EXTENSIONS = [
  'binders',
  'formatters',
  'adapters'
]